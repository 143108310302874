<style>
  body {
    background-color: transparent !important;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    transition: background 1.5s linear;
  }
  .input-group-text {
    padding: 3px !important;
  }
</style>
<template>
  <CContainer class="d-flex content-center min-vh-100">
    <loading :active.sync="loading"></loading>
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody class="text-left">
              <notifications group="autenticando" />
              <notifications group="boasvindas" />
              <CForm v-on:submit.prevent="autenticaUser">
                <h2>Gera Preço ProLucro</h2>
                <p class="text-muted">O melhor sistema de precificação de produtos</p>

                <CInput
                  placeholder="E-mail"
                  required v-model="autentica.email"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="Senha"
                  type="password"
                  required v-model="autentica.password"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CInputCheckbox
                  v-model="autentica.remember_me"
                  label="Lembrar de mim"
                  name="rememberme"
                />
                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton type="submit" color="primary" class="px-4 mt-3">Entrar</CButton>
                    
                  </CCol>
                  <CCol col="6" class="text-right">
                    <div v-html="aguarde"></div>
                    <CButton color="link" class="px-2 mt-2 pt-2" @click="esqueci()"><small>Esqueceu a senha?</small></CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
          <CCard
            color="primary"
            text-color="white"
            class="text-center py-5 d-sm-down-none"
            style="opacity:0.8"
            body-wrapper
          >
            <div align="center"><CImg  
              class="d-block" 
              name="logo" 
              src="/img/prolucro.png"
              height="86px"
              style="margin-left:-42px; margin-top:20px;"
            /></div>
            <p>Administração Simples & Lucrativa</p>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
import { createClient } from 'pexels'
// Chamadas da API
export default {
  name: 'Login',
  data () {
    return {
      autentica: {
        email: '',
        password: '',
        remember_me: true
      },
      notifications: [],
      aguarde: '',
      loading: false
    }
  },
  /* beforeCreate: function () {
    const client = createClient('563492ad6f91700001000001fe1c5c3107314dc785586353614ac743')
    const query = 'Price'
    let img = 'https://www.prolucroconsultoria.com.br/wp-content/uploads/2019/03/blog-profissional.jpg'
    client.photos.search({ query, per_page: 30 }).then(photos => {
      let num = Math.floor((Math.random() * 29))
      if (num === 6) {
        num++
      }
      img = photos.photos[num].src.landscape
      document.body.style.backgroundImage = 'url(\'' + img + '\')'
    })
  }, */
  created: function () {
    var c = document.location.href.split('token=')[1]
    if (c) {
      if (!this.$session.exists()) { this.$session.start() }
      this.$session.set('jwt', c)
      this.$session.set('clipBoard', '')
    }
    this.verificaSessao()
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    autenticaUser: function () {
      this.aguarde = '<div><img src="/img/loading.gif" style="width:50px;" alt="Aguarde..." /></div>'
      this.$http.post(this.URLApi + 'user/session', this.autentica, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        var s = response.data.session_token
        this.$session.start()
        this.$session.set('jwt', s)
        this.$session.set('clipBoard', '')
        this.verificaSessao()
      }, (response) => {
        var m = null
        if (response.body.error.code) {
          m = response.body.error.code
        }
        if (m === 401) {
          this.$notify({
            group: 'autenticando',
            type: 'error',
            title: 'Falha ao autenticar!',
            text: 'E-mail ou senha inválida.'
          })
          this.aguarde = ''
        } else {
          this.$notify({
            group: 'autenticando',
            type: 'error',
            title: 'Falha ao autenticar!',
            text: 'Houve um erro interno de código ' + m
          })
          this.aguarde = ''
        }
      })
    },
    esqueci: function () {
      alert('Para redefinir a sua senha, clique em FORGOT PASSWORD na tela que abrirá a seguir...')
      window.open('https://api.prolucro.com.br/dreamfactory/dist/index.html#/login')
    },
    verificaSessao: function () {
      this.loading = true
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        var tmp = response.body
        this.campo('gp_User', 'usr_ID', 'api_ID = ' + tmp.id).then(
          (response2) => {
            this.$session.set('user_ID', response2)
            this.$session.set('id', tmp.id)
            this.$session.set('email', tmp.email)
            this.$session.set('first_name', tmp.first_name)
            this.$session.set('last_name', tmp.last_name)
            this.$session.set('display_name', tmp.display_name)
            this.$session.set('is_sys_admin', tmp.is_sys_admin)
            this.$session.set('role', tmp.role)
            this.$session.set('last_login_date', tmp.last_login_date)
            this.listar('filtro_Regra', 'role_ID', '(usr_ID = ' + response2 + ') AND (role_ativo = 1) AND (grp_ativo = 1)').then(
              (response) => {
                if (response.length === 0) {
                  this.aguarde = ''
                  this.$notify({
                    group: 'autenticando',
                    type: 'error',
                    title: 'Sem permissão ou erro interno no servidor',
                    text: 'Sua conta não possui um grupo associado ou sua permissão não é suficiente para acessar o sistema'
                  })
                  this.loading = false
                  return false
                } else {
                  this.aguarde = ''
                  this.$session.set('regras', response)
                  this.$session.set('grupo', null)
                  this.$session.set('empresa', null)
                  // Busca o grupo (empresa) para filtrar as queries
                  if (response[0].grp_ID !== 1) { // Se não for administrador master
                    // Busca a empresa na qual faz parte
                    this.campos('filtro_User_Grupo', '(gtipo_ID = 2) AND (usr_ID = ' + response2 + ')').then(
                      (r) => {
                        this.$session.set('grupo', r.grp_ID)
                        this.$session.set('empresa', r.grp_mnemonico)
                        response[0].grp_moeda = r.grp_moeda
                        this.$session.set('regras', response)
                        var urltmp = null
                        if (document.location.hash.indexOf('redir=') > 0) {
                          urltmp = decodeURIComponent(String(document.location.hash).replace('#/login2?redir=', ''))
                        }
                        if (typeof urltmp === 'undefined' || urltmp === '' || urltmp === null) {
                          this.$router.push('/dashboard').catch(()=>{})
                        } else {
                          urltmp = String(urltmp).replace(document.location.origin + '/#', '')
                          this.$router.push(urltmp).catch(()=>{})
                        }
                        this.loading = false
                      },
                      (r) => {
                        console.log(r)
                        this.$notify({
                          group: 'autenticando',
                          type: 'error',
                          title: 'Sem empresa associada',
                          text: 'Sua conta não possui uma empresa associada. Verifique com o administrador.'
                        })
                        this.loading = false
                        return false
                      }
                    )
                  }
                  else
                  {
                    var urltmp = null
                    if (document.location.hash.indexOf('redir=') > 0) {
                      urltmp = decodeURIComponent(String(document.location.hash).replace('#/login2?redir=', ''))
                    }
                    if (typeof urltmp === 'undefined' || urltmp === '' || urltmp === null) {
                      this.$router.push('/dashboard').catch(()=>{})
                    } else {
                      urltmp = String(urltmp).replace(document.location.origin + '/#', '')
                      this.$router.push(urltmp).catch(()=>{})
                    }
                    this.loading = false
                  }
                }
              },
              (response) => {
                this.aguarde = ''
                this.$notify({
                  group: 'autenticando',
                  type: 'error',
                  title: 'Sem permissão',
                  text: 'Sua conta não possui um grupo associado ou sua permissão não é suficiente para acessar o sistema'
                })
                this.loading = false
                return false
              }
            )
          }
        )
      }, (response) => {
        this.aguarde = ''
        var m = 0
        if (response.status === 0) {
          m = 500
        } else {
          m = response.body.error.code
        }
        if (m === 401) {
          this.$notify({
            group: 'autenticando',
            type: '',
            title: 'BEM-VINDO AO GERA PREÇO',
            text: '<p>Um inovador aplicativo de custos e precificação de produtos</p><p><strong>Não possui senha de acesso?</strong><br>Solicite agora em nosso site: <a style=color:white;text-decoration:underline; href=https://www.gerapreco.com.br>www.gerapreco.com.br</a></p>',
            speed: 3000,
            duration: 15000
          })
        }
        if (m === 500) {
          this.$notify({
            group: 'autenticando',
            type: 'error',
            title: 'Fora do ar',
            text: 'O servidor parece que está fora do ar neste instante. Tente novamente mais tarde.'
          })
        }
        this.loading = false
      })
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>
